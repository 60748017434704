import { ButtonStyled } from "./styled";

type Props = {
    text: string;
    color?: "light" | "normal";
    styleButton?: "primary" | "outline";
    handleClick?: () => void | null;
};

export const Button = ({
    text,
    color,
    styleButton,
    handleClick,
}: Props): JSX.Element => (
    <ButtonStyled color={color} styleButton={styleButton} onClick={handleClick}>
        {text}
    </ButtonStyled>
);

Button.defaultProps = {
    color: "normal",
    styleButton: "primary",
    handleClick: null,
};
