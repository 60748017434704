import React from "react";

import { ContainerStyled } from "./styled";

type Props = {
    children: React.ReactNode;
    full?: boolean;
};

export const Container = ({ children, full }: Props): JSX.Element => (
    <ContainerStyled full={full}>{children}</ContainerStyled>
);

Container.defaultProps = {
    full: false,
};
