import styled from "styled-components";

export const ModalBackgroundStyled = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
`;

export const ModalContentStyled = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ModalFormStyled = styled.div`
    width: 500px;
    height: 100%;
    background: #fff;
    position: absolute;
    right: 0px;
    top: 0;
    padding: 64px;
`;
