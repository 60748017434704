import { Route, BrowserRouter as Router } from "react-router-dom";
import HomeLandingPage from "@features/landingpage/views/home";
import HomeRedoc from "@features/redocs/views/home";

const App = () => (
    <Router basename={window._env_.BASE_NAME}>
        <Route exact path="/" component={HomeLandingPage} />
        <Route path="/documentacao/:api" component={HomeRedoc} />
    </Router>
);
export default App;
