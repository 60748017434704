import IconEmail from "@assets/email.png";

import { BoxEmailStyled, BoxEmailTextStyled } from "./styled";

export const BoxEmail = (): JSX.Element => (
    <BoxEmailStyled>
        <img src={IconEmail} alt="e-mail" />
        <BoxEmailTextStyled>developers@farmtech.com.br</BoxEmailTextStyled>
    </BoxEmailStyled>
);
