import { useState } from "react";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Modal from "@components/Modal";

import Body from "../components/Body";

const RedocPage = (): JSX.Element => {
    const [showModal, setShowMolda] = useState(false);
    const handleShowModal = () => {
        setShowMolda(true);
    };
    const handleCloseModal = () => {
        setShowMolda(false);
    };
    return (
        <>
            <Header addModal={handleShowModal} />
            <Body />
            <Footer />
            <Modal closeModal={handleCloseModal} show={showModal} />
        </>
    );
};
export default RedocPage;
