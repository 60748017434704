import styled from "styled-components";

export const FooterStyled = styled.footer`
    width: 100%;
    height: 120px;
    background: #00231d;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

export const FooterContentStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 120px;
`;

export const FooterTextStyled = styled.p`
    font-size: 14px;
    color: #fff;
    margin-left: 24px;
`;
