import React from "react";
import { DescriptionStyled } from "./styled";

type Props = {
    children: React.ReactNode;
};

export const Description = ({ children }: Props): JSX.Element => (
    <DescriptionStyled>{children}</DescriptionStyled>
);
