import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import slugify from "slugify";

type Router = {
    api: string;
};

export const useRedocData = () => {
    const [data, setData] = useState(null);
    const params = useParams<Router>();

    useEffect(() => {
        const dataAll = window._env_.URLS;

        const activeData = dataAll
            .filter((item) => params.api === slugify(item.name).toLowerCase())
            .map((item) => ({
                value: slugify(item.name).toLowerCase(),
                label: item.name,
                url: item.url,
            }));
        setData(activeData[0]);
    }, [params.api]);

    return {
        data,
        setData,
    };
};
