import styled from "styled-components";

type Props = {
    color: string;
};

export const LogoStyled = styled.img<Props>`
    width: ${(props) => (props.color === "normal" ? "125px" : "116px")};
`;
export const LogoHeader = styled.img`
    width: 225px;
`;
