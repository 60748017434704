import LogoSrc from "@assets/farmdev.svg";
import LogoSrcLight from "@assets/farm-logo-light.png";

import { LogoStyled } from "./styled";

type Props = {
    color?: "light" | "normal";
};

export const LogoFarm = ({ color }: Props): JSX.Element => (
    <LogoStyled
        src={color === "normal" ? LogoSrc : LogoSrcLight}
        alt="Logotipo Farm Investimentos"
        color={color}
    />
);

LogoFarm.defaultProps = {
    color: "normal",
};
