import styled from "styled-components";

type Props = {
    full: boolean;
};

export const ContainerStyled = styled.div<Props>`
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: ${(props) => (props.full ? "100%" : "auto")};
`;
