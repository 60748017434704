import styled from "styled-components";

export const DescriptionStyled = styled.p`
    color: #5f5c6b;
    font-size: 14px;
    line-height: 20px;
    margin: 16px 0;
    strong {
        font-weight: bold;
    }
`;
