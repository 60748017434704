import Button from "@components/Button";
import { useLink } from "@hooks/useLink";

import {
    MenuItemStyled,
    MenuListStyled,
    MenuStyled,
    LinkStyled,
} from "./styled";

type Props = {
    addModal: () => void;
};

export const Menu = ({ addModal }: Props): JSX.Element => {
    const { link } = useLink();
    return (
        <MenuStyled>
            <MenuListStyled>
                <MenuItemStyled>
                    <LinkStyled to={`/documentacao/${link}`}>
                        Documentação
                    </LinkStyled>
                </MenuItemStyled>
                <MenuItemStyled>
                    <Button
                        text="Solicitar acesso"
                        styleButton="primary"
                        handleClick={addModal}
                    />
                </MenuItemStyled>
            </MenuListStyled>
        </MenuStyled>
    );
};
