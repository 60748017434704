import { Link } from "react-router-dom";

import { useListApis } from "./useListApis";
import {
    ListApisItemStyled,
    ListApisStyled,
    DescriptionStyled,
    TitleStyled,
    LinkStyled,
} from "./styled";

export const ListApis = (): JSX.Element => {
    const { list } = useListApis();
    return (
        <ListApisStyled>
            {list.map((item) => (
                <ListApisItemStyled key={item.id}>
                    <TitleStyled>{item.name}</TitleStyled>
                    <DescriptionStyled>{item.description}</DescriptionStyled>
                    <Link to={`/documentacao/${item.name.toLowerCase()}`}>
                        <LinkStyled>Saiba mais</LinkStyled>
                    </Link>
                </ListApisItemStyled>
            ))}
        </ListApisStyled>
    );
};
