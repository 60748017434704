import { Link } from "react-router-dom";

import Container from "@components/Container";
import LogoHeader from "@assets/farmdev.svg";
import Menu from "@components/Menu";

import {
    HeaderFixStyled,
    HeaderStyled,
    HeaderFlexStyled,
    HeaderLogoStyled,
} from "./styled";

type Props = {
    addModal: () => void;
};

export const Header = ({ addModal }: Props): JSX.Element => (
    <HeaderStyled>
        <HeaderFixStyled>
            <Container full>
                <HeaderFlexStyled>
                    <Link to="/">
                        <HeaderLogoStyled src={LogoHeader} />
                    </Link>

                    <Menu addModal={addModal} />
                </HeaderFlexStyled>
            </Container>
        </HeaderFixStyled>
    </HeaderStyled>
);
