import styled from "styled-components";

export const HeaderStyled = styled.header`
    width: 100%;
    height: 88px;
    background: #fff;
`;

export const HeaderFixStyled = styled.div`
    width: 100%;
    height: 88px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    border-bottom: 1px solid #f3f3f3;
`;

export const HeaderFlexStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 100%;
`;

export const TextDeveloperStyled = styled.p`
    font-size: 16px;
    color: #2b3340;
`;

export const HeaderLogoStyled = styled.img`
    height: auto;
    width: 225px;
`;
