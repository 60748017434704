import {
    ModalBackgroundStyled,
    ModalContentStyled,
    ModalFormStyled,
} from "./styled";

import ButtonBack from "./components/ButtonBack";
import Title from "./components/Title";
import Description from "./components/Description";
import SubTitle from "./components/SubTitle";
import ListDetails from "./components/ListDetails";
import BoxEmail from "./components/BoxEmail";

type Props = {
    show: boolean;
    closeModal: () => void;
};

export const Modal = ({ show, closeModal }: Props): JSX.Element => (
    <>
        {show && (
            <ModalBackgroundStyled>
                <ModalContentStyled
                    onClick={(event) => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    <ModalFormStyled
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <ButtonBack handleClick={closeModal} />
                        <Title />
                        <SubTitle>Como solicitar acesso?</SubTitle>
                        <Description>
                            Para solicitar seu acesso às APIs da Farm, por favor
                            envie um e-mail para:
                        </Description>
                        <BoxEmail />
                        <Description>
                            Para agilizarmos seu atendimento, não se esqueça de
                            nos enviar:
                        </Description>
                        <ListDetails />
                        <Description>
                            Você receberá uma resposta{" "}
                            <strong>em até 48 horas</strong>.
                        </Description>
                    </ModalFormStyled>
                </ModalContentStyled>
            </ModalBackgroundStyled>
        )}
    </>
);
