import styled from "styled-components";

export const ListStyled = styled.ul`
    width: 100%;
    margin: 16px 0;
    list-style-position: inside;
`;

export const ListItemStyled = styled.li`
    width: 100%;
    color: #5f5c6b;
    font-size: 14px;
    margin-bottom: 8px;
`;
