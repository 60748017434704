import styled from "styled-components";

export const ButtonContentStyled = styled.div`
    width: 100%;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;

export const ButtonBackTextStyled = styled.p`
    font-size: 14px;
    color: #4e4b59;
    font-weight: bold;
    margin-left: 16px;
`;

export const ButtonBackImgStyled = styled.img`
    width: 18px;
    height: 18px;
`;
