import styled from "styled-components";

import LogoSrc from "@assets/banner.jpg";

export const BannerStyled = styled.section`
    width: 100%;
    height: calc(100vh - 88px);
    background: url(${LogoSrc}) no-repeat;
    background-size: cover;
`;

export const BannerContentStyled = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
`;

export const BannerTitleStyled = styled.h2`
    font-size: 36px;
    color: #fff;
    margin-bottom: 16px;
    font-weight: bold;
    width: 600px;
`;

export const BannerTitleIntroductionStyled = styled.h3`
    font-size: 30px;
    color: #fff;
    margin-bottom: 16px;
    font-weight: bold;
`;

export const BannerSubTitleStyled = styled.p`
    font-size: 16px;
    color: #fff;
    margin-bottom: 16px;
`;

export const BannerContentButtonStyled = styled.div`
    width: 400px;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;

    button {
        :first-child {
            margin-right: 35px;
        }
    }
`;
