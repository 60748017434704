import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuStyled = styled.nav``;

export const MenuListStyled = styled.ul`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    list-style: none;
`;

export const MenuItemStyled = styled.li`
    margin-left: 35px;
    cursor: pointer;
`;

export const LinkStyled = styled(Link)`
    text-decoration: none;
    color: #4f8406;
    opacity: 0.86;
    &::hover {
        opacity: 0.73;
    }
`;
