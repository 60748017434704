import styled from "styled-components";

export const ListApisStyled = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-top: 64px;
    flex-wrap: wrap;
`;

export const ListApisItemStyled = styled.li`
    width: 360px;
    height: 240px;
    box-shadow: 0px 12px 20px rgba(29, 35, 39, 0.08),
        0px 2px 6px rgba(29, 35, 39, 0.04), 0px 0px 1px rgba(29, 35, 39, 0.04);
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 32px;
    position: relative;
`;

export const TitleStyled = styled.h4`
    width: 100%;
    margin-bottom: 16px;
    color: #4e4b59;
    font-size: 24px;
    font-weight: bold;
`;

export const DescriptionStyled = styled.p`
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    color: #5f5c6b;
    line-height: 20px;
`;

export const LinkStyled = styled.p`
    width: 100%;
    display: block;
    font-size: 14px;
    color: #4f8406;
    position: absolute;
    left: 32px;
    bottom: 32px;
    cursor: pointer;
    font-weight: bold;
`;
