import styled from "styled-components";

export const BoxEmailStyled = styled.div`
    width: 100%;
    display: flex;
    background: #f6f7f8;
    margin: 24px 0;
    padding: 16px;
    align-items: center;
`;

export const BoxEmailTextStyled = styled.p`
    color: #5f5c6b;
    font-size: 14px;
    margin-left: 16px;
`;
