import LogoFarm from "@components/LogoFarm";

import { FooterContentStyled, FooterStyled, FooterTextStyled } from "./styled";

export const Footer = (): JSX.Element => (
    <FooterStyled>
        <FooterContentStyled>
            <LogoFarm color="light" />
            <FooterTextStyled>
                © Copyright 2023 - Farmtech - Todos os direitos reservados.
            </FooterTextStyled>
        </FooterContentStyled>
    </FooterStyled>
);
