import styled from "styled-components";

export const SectionApisStyled = styled.section`
    width: 100%;
    padding: 64px 0;
    background: #fff;
`;

export const SectionApisTitleStyled = styled.h3`
    font-size: 22px;
    color: #2b3340;
    margin-bottom: 8px;
    font-weight: bold;
`;

export const SectionApisSubTitleStyled = styled.p`
    font-size: 16px;
    color: #2b3340;
    margin-bottom: 16px;
    strong {
        font-weight: bold;
    }
`;
