import { useHistory } from "react-router-dom";

import Container from "@components/Container";
import Button from "@components/Button";
import { useLink } from "@hooks/useLink";

import {
    BannerStyled,
    BannerContentStyled,
    BannerContentButtonStyled,
    BannerTitleStyled,
    BannerSubTitleStyled,
} from "./styled";

type Props = {
    addModal: () => void;
};

export const Banner = ({ addModal }: Props): JSX.Element => {
    const { link } = useLink();
    const history = useHistory();
    const handleClick = () => {
        history.push(`./documentacao/${link}`);
    };
    return (
        <BannerStyled>
            <Container full>
                <BannerContentStyled>
                    <div>
                        <BannerSubTitleStyled>
                            A Farmtech está de portas abertas para novos
                            negócios e parcerias.
                        </BannerSubTitleStyled>
                        <BannerTitleStyled>
                            Documentação para você se integrar com nossas APIs
                        </BannerTitleStyled>
                        <BannerContentButtonStyled>
                            <Button
                                styleButton="primary"
                                text="Documentação"
                                handleClick={handleClick}
                            />
                            <Button
                                styleButton="outline"
                                text="Solicitar acesso"
                                color="light"
                                handleClick={addModal}
                            />
                        </BannerContentButtonStyled>
                    </div>
                </BannerContentStyled>
            </Container>
        </BannerStyled>
    );
};
