import styled from "styled-components";

const primary = "#4F8406";
const light = "#fff";

type PropsButton = {
    color: string;
    styleButton: string;
};

function getStyleOutline(props: PropsButton): string {
    const color = props.color === "light" ? light : primary;
    const background = props.color === "light" ? "transparent" : "#fff";
    return `
        border: 1px solid ${color};
        background: ${background};
        color: ${color};
        cursor:pointer;
    `;
}

function getStylePrimary(props: PropsButton): string {
    const color = props.color === "light" ? light : primary;
    const background = props.color === "light" ? "transparent" : primary;
    return `
        border: 1px solid ${color};
        background: ${background};
        color: #fff;
        cursor:pointer;
    `;
}

function checkButton(props: PropsButton): string {
    if (props.styleButton === "outline") {
        return getStyleOutline(props);
    }
    return getStylePrimary(props);
}

export const ButtonStyled = styled.button<PropsButton>`
    ${(props) => checkButton(props)}
    font-family: "Manrope";
    padding: 10px 20px;
    border-radius: 26px;
    font-size: 16px;
`;
