import { RedocStandalone } from "redoc";

import { useRedocData } from "./useRedocData";
import { RedocDataStyled } from "./styled";

export const RedocData = (): JSX.Element => {
    const { data } = useRedocData();
    return (
        <RedocDataStyled>
            {data && (
                <RedocStandalone
                    specUrl={data.url}
                    options={{
                        nativeScrollbars: true,
                        scrollYOffset: 60,
                        theme: {
                            colors: {
                                primary: { main: window._env_.THEME_COLOR },
                            },
                        },
                    }}
                />
            )}
        </RedocDataStyled>
    );
};
