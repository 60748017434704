import { useState } from "react";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Modal from "@components/Modal";
import Banner from "@features/landingpage/components/Banner";
import SectionApis from "@features/landingpage/components/SectionApis";

const Home = () => {
    const [showModal, setShowMolda] = useState(false);
    const handleShowModal = () => {
        setShowMolda(true);
    };
    const handleCloseModal = () => {
        setShowMolda(false);
    };
    return (
        <>
            <Header addModal={handleShowModal} />
            <Banner addModal={handleShowModal} />
            <SectionApis />
            <Footer />
            <Modal show={showModal} closeModal={handleCloseModal} />
        </>
    );
};

export default Home;
