import React from "react";

import IconLink from "@assets/back-left-arrow.png";

import {
    ButtonContentStyled,
    ButtonBackTextStyled,
    ButtonBackImgStyled,
} from "./styled";

type Props = {
    handleClick: () => void;
};

export const ButtonBack = ({ handleClick }: Props): JSX.Element => (
    <ButtonContentStyled
        onClick={(event) => {
            event.stopPropagation();
            handleClick();
        }}
    >
        <ButtonBackImgStyled src={IconLink} alt="" />
        <ButtonBackTextStyled>Voltar</ButtonBackTextStyled>
    </ButtonContentStyled>
);
