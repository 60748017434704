import React from "react";
import { SubTitleStyled } from "./styled";

type Props = {
    children: React.ReactNode;
};

export const SubTitle = ({ children }: Props): JSX.Element => (
    <SubTitleStyled>
        <strong>{children}</strong>
    </SubTitleStyled>
);
