import Container from "@components/Container";
import ListApis from "../ListApis";

import { SectionApisStyled, SectionApisTitleStyled } from "./styled";

export const SectionApis = (): JSX.Element => (
    <SectionApisStyled>
        <Container>
            <SectionApisTitleStyled>
                Saiba mais sobre as nossas APIs
            </SectionApisTitleStyled>
            <ListApis />
        </Container>
    </SectionApisStyled>
);
